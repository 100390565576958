Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ragretrievalaugmentedgeneration1";
exports.labelBodyText = "ragretrievalaugmentedgeneration1 Body";
exports.ApiContentType = 'application/json';
exports.sendQueryEndPoint = '/bx_block_rag/generate_response'; 
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End