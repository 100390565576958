Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "bx_block_rag_admin_manager/documents";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RAG Admin Manager";
exports.labelBodyText = "ragadminmanager Body";
exports.saveLabel = 'Save'
exports.cancelLabel = "Cancel"
exports.templateLabel = "Template Name"
exports.categoryLabel = "Category"
exports.versionLabel = "Version"
exports.btnExampleTitle = "CLICK ME";
exports.subCategoryLabel = "Sub Category"
exports.samplePromptsLabel = "Sample prompts:"
exports.uploadBtnLabel = "Upload file"
exports.linkBtnLabel = "Enter a Link"
exports.siteMapBtnLabel = "Add Sitemap"
exports.faqBtnLabel = "FAQ"
exports.dragMessage = 'Drop the files here...'
exports.documentUploadApi = '/bx_block_rag_admin_manager/documents'
exports.chunkApiEndPoint = "/bx_block_rag_admin_manager/chunks"
exports.enterrisk = "Evaluate risk tolerance for new investors"
exports.entertrend = "Identify renewable energy trends"


// Customizable Area End