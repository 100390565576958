import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent } from "react";
interface ChatMessage {
  sender: "user" | "bot";
  text: string;
}



const responseData = {
  "data": {
    "id": "1",
    "type": "user_query",
    "attributes": {
      "id": 1,
      "query": "what is ror",
      "content": "The term \"ROR\" can refer to different things depending on the context:\n\n1. **Return on Revenue (ROR)**: This financial metric measures a company's profitability by comparing its revenue to its net income. It is calculated by dividing net income by total revenue, providing insight into how efficiently a company turns revenue into profit.\n\n2. **Ruby on Rails (RoR)**: A popular web application framework written in the Ruby programming language. It follows the convention over configuration (CoC) and the DRY (Don't Repeat Yourself) principles, making web development faster and more efficient.\n\n3. **Rate of Return (ROR)**: In finance, this term refers to the gain or loss made on an investment over a specific period, expressed as a percentage of the investment's initial value.\n\n4. **Rough Order of Magnitude (ROR)**: This is an estimation used in project management to give a rough idea of the costs or resources needed for a project, often before detailed planning begins.\n\nPlease provide more context if you are referring to a specific \"ROR\" so I can give a more precise answer!",
      "created_at": "2024-09-27T06:37:29.745Z",
      "updated_at": "2024-09-27T06:37:29.745Z"
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  currentMessage: string;
      messages: ChatMessage[];
  // Customizable Area Start
  queryResponse: string;
  isLoading: boolean;
  isTyping: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ragretrievalaugmentedgeneration1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendChatQueryApiCallID: string = ''
  fullText: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentMessage: "",
      messages: [], 
      queryResponse: '',
      isLoading: false,
      isTyping: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fullText = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentMessage: e.target.value });
  };

  handleSendMessage = () => {
    const { messages, currentMessage } = this.state;

    if (currentMessage.trim() === "") return;

    const newMessages: ChatMessage[] = [
      ...messages,
      { sender: "user", text: currentMessage }, 
    ];

    const dummyResponse = "This is a dummy response! for our RAG and once the API  will be integrated everything will be upto date";
    setTimeout(() => {
      this.setState({
        messages: [...newMessages, { sender: "bot", text: dummyResponse }],
        currentMessage: "",
      });
    }, 1000);

    this.setState({
      messages: newMessages,
      currentMessage: "",
    })
  }
  handleQueryResponse = () => {
    this.fullText = responseData.data.attributes.content;
    this.startTyping();
  }

  startTyping = () => {
    let currentIndex = 0;

    const typeLetter = () => {
      if (currentIndex < this.fullText.length) {
        this.setState({
          queryResponse: this.state.queryResponse + this.fullText[currentIndex],
        });

        currentIndex++;
        setTimeout(typeLetter, 10);
      }else {
        this.setState({ isTyping: false })
      }
    };

    typeLetter();
  };


  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  sendQueryToChatBot = async () => {
    this.setState({ txtInputValue: 'what is ror', isLoading: true });
    setTimeout(() => {
      this.handleQueryResponse()
      this.setState({ isLoading: false, isTyping: true })
    }, 2000);
    const httpBody = {
      "query": this.state.txtInputValue
    }
    this.sendChatQueryApiCallID = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.sendQueryEndPoint,
      body: httpBody
    });
  };


  // Customizable Area End
}
