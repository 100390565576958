import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import {Paper, List, ListItem, Avatar, Box,  } from "@material-ui/core";

import { styled } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import SendIcon from '@mui/icons-material/Send'; 

// Customizable Area End

import Ragretrievalaugmentedgeneration1Controller, {
  Props,
  configJSON,
} from "./Ragretrievalaugmentedgeneration1Controller";

export default class Ragretrievalaugmentedgeneration1 extends Ragretrievalaugmentedgeneration1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <FullScreenContainer>
      <StyledPaper>
        <Header>RAG</Header>
        <ChatContainer>
          <StyledList>
            {this.state.messages.map((msg, index) => (
              <ListItem data-test-id={`container${index}`} key={index} style={{ justifyContent: "flex-start" }}>
                <Box style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  maxWidth: '60%',
                }}>
                  <Avatar 
                    alt={ "You" } 
                    src={ "https://randomuser.me/api/portraits/men/30.jpg"} 
                    style={{ marginRight: '8px', marginTop: '5px' }} 
                  />
                  <MessageBox
                    style={{
                      backgroundColor:  '#ffffff',
                    }}
                  >
                    <Typography>{msg.text}</Typography>
                  </MessageBox>
                </Box>
              </ListItem>
            ))}
          </StyledList>
        </ChatContainer>
        <InputContainer>
          <InputRoot>
            <StyledTextField
            data-test-id="input-field"
              placeholder="Type your message..."
              value={this.state.currentMessage}
              onChange={this.handleInputChange}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (e.key === "Enter") this.handleSendMessage();
              }}
            />
            <StyledSendButton
            data-test-id="send-button"
              onClick={this.handleSendMessage}
              disabled={!this.state.currentMessage} 
            >
              <SendIcon />
            </StyledSendButton>
          </InputRoot>
        </InputContainer>
      </StyledPaper>
    </FullScreenContainer>

      // Customizable Area End
    );
  }
}

// Customizable Area Start


const FullScreenContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100vh',
  width: '100vw',
  overflow: 'hidden',
});

const Header = styled('div')({
  padding: '20px',
  color: 'black',
  fontWeight: 700,
  fontSize: '20px',
  backgroundColor: '#f1f5f9',
});

const ChatContainer = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '20px',
  backgroundColor: '#f1f5f9',
});

const InputContainer = styled('div')({
  padding: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: '#f1f5f9',
});

const InputRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '90%',
  borderRadius: '35px', 
  border: '1px solid #ccc', 
  backgroundColor: '#f4f4f4', 
  padding: '5px 5px',
});

const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

const StyledList = styled(List)({
  height: '100%',
  overflowY: 'auto',
});

const StyledTextField = styled(InputBase)({
  flexGrow: 1,
  marginLeft: '10px',
  padding: '10px',
  borderRadius: '30px',
  fontSize: '16px',
});

const StyledSendButton = styled(IconButton)({
  color: "#1F73E2",
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const MessageBox = styled(Box)({
  padding: '10px',
  borderRadius: '0px 8px 8px 8px',
  maxWidth: '100%',
  margin: '5px',
  color: '#000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

// Customizable Area End
