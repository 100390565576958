// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Ragadminmanager from "../../blocks/ragadminmanager/src/Ragadminmanager.web";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Promptmanager1 from "../../blocks/promptmanager1/src/Promptmanager1";
import Ragretrievalaugmentedgeneration1 from "../../blocks/ragretrievalaugmentedgeneration1/src/Ragretrievalaugmentedgeneration1";
import Cfassetdepreciationcalculation from "../../blocks/cfassetdepreciationcalculation/src/Cfassetdepreciationcalculation";
import ChatgptWeb from "../../blocks/chatgpt/src/Chatgpt.web"


const routeMap = {
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Ragadminmanager:{
 component:Ragadminmanager,
path:"/Ragadminmanager"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Promptmanager1:{
 component:Promptmanager1,
path:"/Promptmanager1"},
Ragretrievalaugmentedgeneration1:{
 component:Ragretrievalaugmentedgeneration1,
path:"/Ragretrievalaugmentedgeneration1"},
Cfassetdepreciationcalculation:{
 component:Cfassetdepreciationcalculation,
path:"/Cfassetdepreciationcalculation"},

ChatgptWeb:{
  component:ChatgptWeb,
 path:"/Chatgpt"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;