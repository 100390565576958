// Customizable Area Start
import React from 'react';

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  // Customizable Area Start
  // Customizable Area End
} from '@mui/material';

// Customizable Area Start
interface MessageArray {
  id: number;
  message: string;
  isUser: boolean;
}

// Customizable Area End

import ChatgptController, { Props, configJSON } from './ChatgptController';

export default class Chatgpt extends ChatgptController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  renderitem = (item: MessageArray) => {
    return (
      <Box
        sx={
          webStyle.messageContainer && item?.isUser
            ? webStyle.userMessageContainer
            : webStyle.aiMessageContainer
        }
      >
        <Typography
          style={
            item?.isUser ? webStyle.userMessageText : webStyle.aiMessageText
          }
        >
          {item?.message}
        </Typography>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.container}>
        <Box data-test-id="Dataid" sx={webStyle.chatList}>
          {this.state.messages.map((item) => this.renderitem(item))}
        </Box>
        <Box sx={webStyle.inputContainer}>
          <TextField
            data-test-id="textAdd"
            style={webStyle.inputStyle}
            value={this.state.input}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.newState(event)
            }
            placeholder="Type your message here..."
            id="outlined-basic"
            label="Type here"
            variant="outlined"
          />

          <Button
            data-test-id="btnAddExample"
            style={webStyle.buttonStyle}
            onClick={this.sendMessages}
            disabled={this.state.isLoading}
            variant="contained"
          >
            Send
          </Button>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: "5px",
  },
  buttonStyle: {
    width: '10%',
    height: '45px',
    border: 'none',
    backgroundColor: 'skyblue',
    marginLeft: '10px',
  },
  container: {
    flex: 1,
    backgroundColor: "#f5f5f5",
  },
  chatList: {
    paddingHorizontal: "10px",
    marginBottom: "40px",
    paddingBottom: "40px",
  },
  inputContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: 10,
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  input: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 8,
    fontSize: 16,
    marginRight: 10,
    outerWidth: 400,
    innerWidth: 200,
  },
  sendButton: {
    backgroundColor: "#1a73e8",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    width: 50,
    height: 50,
  },
  sendButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 16,
  },
  messageContainer: {
    borderRadius: 10,
    marginBottom: 8,
    padding: 10,
    maxWidth: "100%",
  },
  userMessageContainer: {
    justifyContent: "flex-end",
    display: 'flex',
    marginRight: 10,
  },
  aiMessageContainer: {
    justifyContent: "flex-start",
    display: 'flex',
    marginLeft: 10,
  },

  userMessageText: {
    color: "#fff",
    // textAlign: 'end',
    padding: '20px',
    backgroundColor: "blue",
    borderRadius: "20px",
    fontSize: 16,
  },
  aiMessageText: {
    color: "#333",
    // textAlign: "start",
    padding: '20px',
    backgroundColor: "lightgrey",
    borderRadius: "20px",
  },
};
// Customizable Area End

// Customizable Area End
