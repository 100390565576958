import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import DocumentPicker from 'react-native-document-picker';
interface FileType {
  name: string,
  type: string,
  uri: string
}
import React from "react";

export interface DataResp {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "content": string,
    "created_at": string,
    "updated_at": string
  }
};
interface TableData {
  "data": {
    "data": DataResp[]
  },
  "message": string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  subCategoryValue: string;
  documentdata: FileType,
  listdata: string[]
  templateName: string;
  version: string;
  category: string;
  subCategory: string;
  prompt: string;
  samplePrompts: string[];
  isDragging: boolean;
  files: File[];
  activeBox: number;
  isActiveBtn: boolean;
  fileInputRef: React.RefObject<HTMLInputElement>;
  tableData: TableData;
  openModalDetail: boolean;
  objDetails: DataResp;
  totalPagesTable: number;
  itemsPerPage: number;
  currentPage: number;
  iamValue: string;
  categoryvalue: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RagadminmanagerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apidocumentFormId: string='';
  documentUploadApi: string = '';
  getChunksAPiCall: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
     
      iamValue: "",
     
      categoryvalue:"",
      
      subCategoryValue:"",
      documentdata: {
        name: "",
        type: "",
        uri: ""
    },
    listdata: [],
  
      templateName: '',
      version: '',
      category: '',
      subCategory: '',
      prompt: '',
      samplePrompts: ['Evaluate risk tolerance for new investors', 'Identify renewable energy trends'],
      isDragging: false,
      files: [],
      activeBox: 0,
      isActiveBtn: false,
      fileInputRef: React.createRef(),
      tableData: {
        data: {
          data: [{
            "id": "1",
            "type": "chunk",
            "attributes": {
              "id": 1,
              "content": "DummyPDF",
              "created_at": "2024-09-27T07:43:46.698Z",
              "updated_at": "2024-09-27T07:43:46.698Z"
            }
          },
          {
            "id": "2",
            "type": "chunk",
            "attributes": {
              "id": 2,
              "content": "file",
              "created_at": "2024-09-27T07:43:46.780Z",
              "updated_at": "2024-09-27T07:43:46.780Z"
            }
          }, {
            "id": "1",
            "type": "chunk",
            "attributes": {
              "id": 1,
              "content": "DummyPDF",
              "created_at": "2024-09-27T07:43:46.698Z",
              "updated_at": "2024-09-27T07:43:46.698Z"
            }
          },]
        }, message: ""
      },
      openModalDetail: false,
      objDetails: { id: "", type: "", attributes: { content: "", created_at: "", id: 0, updated_at: "" } },
      itemsPerPage: 5,
      totalPagesTable: 0,
      currentPage: 1,
     
     
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.apidocumentFormId) {
          alert(JSON.stringify(responseJson.message)) 
          let csvType: FileType = {
            name: '',
            type: '',
            uri: ''
          }
          this.setState({documentdata:csvType})
         
        }else{
          alert(JSON.stringify(responseJson.message))          
        }
      }
    }
    this.handlePaginationData.bind(this)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.txtInputWebProps

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
 
  UploadFn = async () => {
    const resdata = await DocumentPicker.pick({
      type: [DocumentPicker.types.pdf,DocumentPicker.types.images],
    });
    let csvType: FileType = {
      name: resdata[0].name!,
      type: resdata[0].type!,
      uri: resdata[0].uri
  }
    this.setState({documentdata:csvType})
  }
  savedata =()=>{
    let formData = new FormData();
    formData.append("document[file]", JSON.parse(JSON.stringify(this.state.documentdata))); 
        
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apidocumentFormId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),{}
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   
  async componentDidMount() {
    this.handlePaginationData()
  }

  handlePaginationData = () => {
    if (this.state.tableData.data.data.length > 0 && this.state.tableData.data.data.length % this.state.itemsPerPage === 0) {
      this.setState({
        totalPagesTable: this.state.tableData.data.data.length / this.state.itemsPerPage
      })
    }
    else {
      this.setState({
        totalPagesTable: Math.floor(this.state.tableData.data.data.length / this.state.itemsPerPage) + 1
      })
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.value } as unknown as Pick<S, keyof S>);
  };
  handleSelectChange = (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
  };
  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ isDragging: true });
  };
  handleDragLeave = () => {
    this.setState({ isDragging: false });
  };
  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ isDragging: false });
    const files = Array.from(event.dataTransfer.files);
    this.setState((prevState) => ({
      files: [...prevState.files, ...files]
    }));
  };

  handleButtonClick = (index: number) => {
    this.setState({ activeBox: index, isActiveBtn: true });
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    this.setState((prevState) => ({
      files: [...prevState.files, ...files]
    }));
  };

  handleOpenDetails = (data: DataResp) => {
    this.setState({ openModalDetail: true, objDetails: data })
  }
  closeModalDetail = () => {
    this.setState({ openModalDetail: false })
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page })
  }

  handleUploadDocument = () => {
    const formdata = new FormData();
    this.state.files.forEach((value) => {
      formdata.append("document[file]", JSON.stringify(value))
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.documentUploadApi
    );

    this.documentUploadApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
