import React from "react";

// Customizable Area Start

import { Input, Button as BuildButton, Typography as BuildTypo } from '@builder/component-library';


import { TextField, MenuItem, Button, Box, Button as MuiButton, Modal, Chip, Card, CardContent, Typography as MuiTypography, Grid, Select, Table, TableHead, TableBody, TableCell, TableRow, Pagination } from '@mui/material';
import { styled } from '@mui/styles';
import { DataResp } from "./RagadminmanagerController";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';

export const BButton = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
});

export const BInput = styled(TextField)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
});

export const BTypoGraphy = styled(MuiTypography)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
});

// Customizable Area End

import RagadminmanagerController, {
  Props,
  configJSON,
} from "./RagadminmanagerController";

export default class Ragadminmanager extends RagadminmanagerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Input style={{display:'none'}}/> <BuildButton style={{display:'none'}}/> <BuildTypo style={{display:'none'}}>hello</BuildTypo>
        <Box sx={webStyle.container}>
          <Box sx={webStyle.headerBox}>
            <MuiTypography variant="h4" gutterBottom>
              RAG Admin Manager
            </MuiTypography>
            <Box>
              <MuiButton data-testid="handleUploadDocument" onClick={this.handleUploadDocument} variant="contained" sx={webStyle.gradientButton}>
                Save
              </MuiButton>
              <MuiButton variant="text" sx={webStyle.cancelButton}>{configJSON.cancelLabel}</MuiButton>
            </Box>
          </Box>
          <Card sx={webStyle.cardContainer}>
            <CardContent>
              <Grid container sx={webStyle.grid} spacing={2}>
                <Grid item sx={webStyle.grid} xs={6}>
                  <MuiTypography sx={webStyle.label}>Template Name</MuiTypography>
                  <TextField
                    name="templateName"
                    data-testid='templateName'
                    placeholder="Template Name"
                    value={this.state.templateName}
                    onChange={this.handleInputChange}
                    fullWidth
                    sx={{
                      ...webStyle.formField,
                    }}
                  />
                  <MuiTypography sx={webStyle.label}>Category</MuiTypography>
                  <Select
                    name="category"
                    data-testid='category'
                    value={this.state.category}
                    onChange={this.handleSelectChange}
                    fullWidth
                    displayEmpty
                    sx={{
                      ...webStyle.formField,
                      ...webStyle.selectField,
                    }}
                  >
                    <MenuItem value="" disabled>Category</MenuItem>
                    <MenuItem value="Category 1">Category 1</MenuItem>
                    <MenuItem value="Category 2">Category 2</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <MuiTypography sx={webStyle.label}>Version</MuiTypography>
                  <Select
                    name="version"
                    value={this.state.version}
                    onChange={this.handleSelectChange}
                    data-testid='Version'
                    fullWidth
                    displayEmpty
                    variant='outlined'
                    sx={{
                      ...webStyle.formField,
                      ...webStyle.selectField,
                    }}
                  >
                    <MenuItem value="" disabled>Version</MenuItem>
                    <MenuItem value="Version 1">Version 1</MenuItem>
                    <MenuItem value="Version 2">Version 2</MenuItem>
                  </Select>
                  <MuiTypography sx={webStyle.label}>Sub Category</MuiTypography>
                  <Select
                    name="subCategory"
                    value={this.state.subCategory}
                    onChange={this.handleSelectChange}
                    fullWidth
                    displayEmpty
                    sx={{
                      ...webStyle.formField,
                      ...webStyle.selectField,
                    }}
                  >
                    <MenuItem value="" disabled> Sub Category</MenuItem>
                    <MenuItem value="Sub Category 1">Sub Category 1</MenuItem>
                    <MenuItem value="Sub Category 2">Sub Category 2</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <MuiTypography variant="body1" sx={webStyle.prompHeading} gutterBottom>
                Sample prompts:
              </MuiTypography>
              <Box sx={webStyle.chipBox}>
                {this.state.samplePrompts.map((promptText, index) => (
                  <Chip sx={webStyle.chip} key={index} label={promptText} />
                ))}
              </Box>
              <TextField
                name="prompt"
                placeholder='Prompt'
                value={this.state.prompt}
                onChange={this.handleInputChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                inputProps={{ maxLength: 100 }}
                helperText={`${this.state.prompt.length}/100`}
                sx={webStyle.promptField}
              />
              <Box sx={webStyle.dragButtonBox}>
                <MuiButton data-testid='activeBox0' sx={webStyle.dragButton(this.state.activeBox === 0)} onClick={this.handleButtonClick.bind(this, 0)}>
                  Upload file
                </MuiButton>
                <MuiButton data-testid='activeBox1' sx={webStyle.dragButton(this.state.activeBox === 1)} onClick={this.handleButtonClick.bind(this, 1)}>
                  Enter a Link
                </MuiButton>
                <MuiButton data-testid='activeBox2' sx={webStyle.dragButton(this.state.activeBox === 2)} onClick={this.handleButtonClick.bind(this, 2)}>
                  Add Sitemap
                </MuiButton>
                <MuiButton data-testid='activeBox3' sx={webStyle.dragButton(this.state.activeBox === 3)} onClick={this.handleButtonClick.bind(this, 3)}>
                  FAQ
                </MuiButton>
              </Box>
              <label htmlFor="uploadFile">
                {this.state.activeBox === 0 && <><Box
                  sx={{
                    ...webStyle.dragDropArea,
                    ...(this.state.isDragging && webStyle.dragDropActive),
                  }}
                  data-testid='dragBoxId'
                  onDragOver={this.handleDragOver}
                  onDragLeave={this.handleDragLeave}
                  onDrop={this.handleDrop}
                >
                  <MuiTypography sx={webStyle.dragText} variant="body1">
                    {this.state.isDragging ? 'Drop the this.state.files here...' : 'Upload template'}
                  </MuiTypography>
                  <MuiTypography variant="body2" color="textSecondary">
                    Click or drag a file you want to upload
                  </MuiTypography>
                  <TextField
                    sx={{
                      ...webStyle.dragDropArea,
                      ...(this.state.isDragging && webStyle.dragDropActive),
                    }}
                    id='uploadFile'
                    data-testid="uploadFile"
                    ref={this.state.fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange}
                  />
                </Box>
                  {this.state.files && (
                    <Box
                      sx={webStyle.fileListContainer}
                    >
                      {Array.from(this.state.files).map((file, index) => (
                        <MuiTypography sx={webStyle.fileListItem} key={index}>{file.name}</MuiTypography>
                      ))}
                    </Box>
                  )}
                </>}
              </label>
              {this.state.activeBox === 1 &&
                <Box >
                  <TextField
                    name="enterLink"
                    placeholder="Enter Link"
                    value={this.state.templateName}
                    onChange={this.handleInputChange}
                    fullWidth
                    sx={{
                      ...webStyle.formField,
                      ...webStyle.uploadField
                    }}
                  /></Box>}
              {this.state.activeBox === 2 &&
                <Box>
                  <TextField
                    name="addSitemap"
                    placeholder="Add Sitemap"
                    value={this.state.templateName}
                    onChange={this.handleInputChange}
                    fullWidth
                    sx={{
                      ...webStyle.formField,
                      ...webStyle.uploadField
                    }}
                  />
                </Box>}
              {this.state.activeBox === 3 &&
                <Box >
                  <TextField
                    name="faq"
                    placeholder="Faq"
                    value={this.state.templateName}
                    onChange={this.handleInputChange}
                    fullWidth
                    sx={{
                      ...webStyle.formField,
                      ...webStyle.uploadField
                    }}
                  /></Box>}
            </CardContent>
          </Card>
          <Box>
            <MuiTypography sx={webStyle.prompHeading} >Training data</MuiTypography>
            <Card sx={{
              ...webStyle.cardContainer,
              ...webStyle.trainingContainer
            }
            }>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={webStyle.label}>Tile</TableCell>
                    <TableCell sx={webStyle.label}>File type</TableCell>
                    <TableCell sx={webStyle.label}>Updated On</TableCell>
                    <TableCell sx={webStyle.label}>Status</TableCell>
                    <TableCell sx={webStyle.label}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {

                    this.state.tableData.data.data.slice(
                      (this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))?.map((value: DataResp) => {
                        return <TableRow>
                          <TableCell>{value.attributes.content}</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>{value.attributes.updated_at}</TableCell>
                          <TableCell><VisibilityIcon data-testid="handleOpenDetails" onClick={this.handleOpenDetails.bind(this, value)} /></TableCell>
                          <TableCell><DeleteIcon /></TableCell>
                        </TableRow>
                      })
                  }
                </TableBody>
              </Table>
            </Card>
            <Pagination
              count={this.state.totalPagesTable}
              page={this.state.currentPage}
              variant="outlined"
              shape="rounded"
              size="large"
              siblingCount={0}
              data-testid="handlePageChange"
              onChange={this.handlePageChange}
            />
          </Box>
          <Modal open={this.state.openModalDetail} onClose={this.closeModalDetail}>
            <>
              <Box margin={"20%"}>
                <Box
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    display: "flex",
                    flexDirection: "column",
                    height: 'fit',
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 'fit',
                    position: "absolute"
                  }}
                >
                  <ClearIcon
                    style={{
                      position: "absolute",
                      top: "40px",
                      right: "40px",
                      cursor: "pointer",
                    }}
                    data-testid="closeModalDetail"
                    onClick={this.closeModalDetail}
                  />

                  <Box
                    mt={"5rem"}
                    mb={"5rem"}
                    padding={'20px'}
                  >
                    <Grid container sx={webStyle.grid} spacing={2}>
                      <Grid item sx={webStyle.grid} xs={6}>
                        <MuiTypography sx={webStyle.label}>Title</MuiTypography>
                        <TextField
                          name="title"
                          data-testid="title"
                          placeholder="Title"
                          value={this.state.objDetails.attributes.content}
                          InputProps={{ readOnly: true }}
                          fullWidth
                          sx={{
                            ...webStyle.formField,
                          }}
                        />
                        <MuiTypography sx={webStyle.label}>Update on</MuiTypography>
                        <TextField
                          name="updateOn"
                          value={this.state.objDetails.attributes.updated_at}
                          placeholder="Update On"
                          data-testid="Version"
                          fullWidth
                          InputProps={{ readOnly: true }}
                          variant="outlined"
                          sx={{
                            ...webStyle.formField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MuiTypography sx={webStyle.label}>File Type</MuiTypography>
                        <TextField
                          name="fileType"
                          data-testid="fileType"
                          placeholder="File Type"
                          value="-"
                          fullWidth
                          InputProps={{ readOnly: true }}
                          sx={{
                            ...webStyle.formField,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </>
          </Modal>

        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  container: {
    p: 3,
    width: '80rem',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F1F5F9',
    textAlign: 'left'
  },
  headerBox: {
    width: '100%',
    mb: 2,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  gradientButton: {
    background: 'linear-gradient(115deg, #B084EE 10%, #5D17BD 90%)',
    fontWeight: 'bold',
    borderRadius: 3,
    textTransform: "capitalize",
    border: 0,
    color: 'white',
    fontSize: "15.5px",
    letterSpacing: ".4px",
    height: 48,
    padding: '0 30px',
    '&:hover': {
      background: 'linear-gradient(115deg, #B084EE 10%, #5D17BD 90%)',
      boxShadow: "none",
    },
  },
  cancelButton: {
    color: 'gray',
    fontWeight: 'bold',
    textTransform: "capitalize",
    fontSize: "15.5px",
    letterSpacing: ".4px",
    height: 48,
    padding: '0 10px',
  },
  formField: {
    width: '100%',
    marginBottom: '20px',
    marginTop: '5px',
    borderRadius: '0.8rem',
    height: '2.9rem',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.8rem',
      height: '2.9rem',
      '& fieldset': {
        borderColor: '#CBD5E1',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  selectField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  promptField: {
    marginTop: '10px',
  },
  label: {
    fontWeight: 'bold',
    colors: '#475569'
  },
  prompHeading: {
    color: '#4B596A',
  },
  chipBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    mb: 2,
    mt: 1,
  },
  chip: {
    borderRadius: 1,
    p: 2,
    background: '#E2E8F0',
    fontSize: '16px',
  },
  cardContainer: {
    mb: 2,
    p: 2,
    height: 'auto',
    overflowY: 'auto',
    maxHeight: '700px',
    width: '55rem',
  },
  trainingContainer: {
    p: 0,
    width: '57rem'
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dragButton: (activeBox: boolean) => ({
    width: '150px',
    height: '40px',
    fontWeight: 'bold',
    color: '#6200EA',
    textTransform: 'capitalize' as 'capitalize',
    '&:hover': {
      border: '1px solid #E6D5FF',
      borderRadius: 8,
      backgroundColor: '#F0E5FF',
      boxShadow: 'none',
    },
    backgroundColor: activeBox ? '#F0E5FF' : '',
    border: '1px solid #E6D5FF',
    borderRadius: 8,
  }),
  dragButtonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dragDropArea: {
    border: '2px dashed #E2E8EF',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center' as 'center',
    cursor: 'pointer',
    marginTop: '15px',
    marginBottom: '15px',
  },
  dragDropActive: {
    backgroundColor: '#E0D4FF',
  },
  dragText: {
    fontWeight: 'bold'
  },
  uploadField: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  fileListContainer: {
    maxHeight: '40px',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    padding: '10px',
  },
  fileListItem: {
    cursor: 'pointer'
  },
}

// Customizable Area End
